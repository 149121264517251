<template>
    <div class="page-body">
        <div class="data-dispaly">
            <div class="add-parent">
                <div>
                    <el-button @click="append(0,null,null)"
                               type="primary"
                               icon="el-icon-circle-plus"
                               style="cursor: pointer;"
                               size="mini">新增
                    </el-button>
                </div>
            </div>
            <div class="data-body">
                <el-tree
                        :data="dataTree"
                        node-key="id"
                        default-expand-all
                        :expand-on-click-node="false">
                    <span class="custom-tree-node"
                          @mouseover="displayOper(data.id)"
                          @mouseleave="hiddenOper(data.id)"
                          slot-scope="{ node, data }">
                        <div class="text-area">
                            <span v-if="data.isDisabled == 1" style="color: #cccccc">{{ data.name}}</span>
                            <span v-else>{{ data.name}}</span>
                        </div>
                        <div class="text-oper" v-if="data.isDisabled == 1"
                             :style="{'visibility': treeId==data.id?isDisplay:'hidden'}">
                            <i class="iconfont iconbianji" title="编辑数据字典"
                               @click="editData(data.id,data.wholeCode,data.name)"></i>
                            <i class="iconfont iconshanchu" title="删除数据字典"
                               @click="deleteData(data.id)"></i>
                        </div>
                        <div class="text-oper" v-else :style="{'visibility': treeId==data.id?isDisplay:'hidden'}">
                            <i class="iconfont iconzengjia" title="新增数据字典"
                               @click="append(data.wholeCode,data.name,data.children.length)"></i>
                            <i class="iconfont iconbianji" title="编辑数据字典"
                               @click="editData(data.id,data.wholeCode,data.name)"></i>
                            <i class="iconfont iconshanchu" title="删除数据字典"
                               @click="deleteData(data.id)"></i>
                        </div>
                    </span>
                </el-tree>
            </div>
        </div>
        <div class="edit_dialog">
            <el-dialog
                    :title="editTitle"
                    :visible.sync="editDialogVisible"
                    :close-on-click-modal="false"
                    width="70%" center>
                <el-form :model="dataForm" :rules="validation" ref="dataForm" label-width="100px" class="demo-ruleForm">
                    <el-row>
                        <el-col style="width: 50%">
                            <el-form-item label="父级字典" prop="parentCode">
                                <el-input disabled v-model="parentName"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col style="width: 50%">
                            <el-form-item label="字典排序" prop="sort">
                                <el-input v-model.number="dataForm.sort"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col style="width: 50%">
                            <el-form-item label="字典名称" prop="name">
                                <el-input v-model="dataForm.name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col style="width: 50%">
                            <el-form-item label="字典编码" prop="code">
                                <el-input v-model="dataForm.code" v-if="type == 0" placeholder="建议输入英文或者是拼音"></el-input>
                                <el-input v-model="dataForm.code" v-else disabled></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
<!--                        <el-col style="width: 50%">-->
<!--                            <el-form-item label="字典数值" prop="value">-->
<!--                                <el-input v-model="dataForm.value"></el-input>-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->
                        <el-col style="width: 50%">
                            <el-form-item label='是否停用' prop="value">
                                <el-switch
                                        v-model="dataForm.isDisabled"
                                        active-color="#ff4949"
                                        inactive-color="#13ce66">
                                </el-switch>
                                <span v-if="dataForm.isDisabled == 1" style="margin-left: 10px">停用</span>
                                <span v-else style="margin-left: 10px">启用</span>
                            </el-form-item>
                        </el-col>
                        <el-col style="width: 50%">
                            <el-form-item label="全编码" prop="wholeCode">
                                <el-input v-model="dataForm.wholeCode" disabled></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="备注" prop="remark">
                        <el-input type="textarea" rows="3" v-model="dataForm.remark"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancel('dataForm')">取 消</el-button>
                    <el-button type="primary" :loading="isLoading" @click="submit('dataForm',type)">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import dataDictionary from '../../../public/json/dataDictionary.json'

    export default {
        data() {
            return {
                dataTree: [],
                isLoading: false,
                editDialogVisible: false,
                editTitle: '',
                dataForm: {
                    id: null,
                    parentCode: '',
                    code: '',
                    wholeCode: '',
                    name: '',
                    value: '',
                    remark: '',
                    sort: '',
                    isDisabled: '',
                },
                type: 0,
                parentData: {
                    code: '',
                    name: ''
                },
                validation: {
                    name: [
                        {required: true, message: '请输入数据字典名称', trigger: 'blur'},
                    ],
                    code: [
                        {required: true, message: '请输入数据字典编码', trigger: 'blur'},
                    ],
                    sort: [
                        {required: true, message: '请输入数据排列顺序', trigger: 'blur'},
                        {type: 'number', message: '排列顺序必须为数字值'}
                    ]
                },
                isDisplay: 'hidden',
                treeId: '',
                parentName: ''
            };
        },

        methods: {
            append(code, name,sort) {
                this.type = 0;
                this.editDialogVisible = true;
                this.editTitle = '新增数据字典信息';
                this.parentName = name;
                this.dataForm = {
                    id: null,
                    parentCode: code == null ? "" : code,
                    code: '',
                    wholeCode: '',
                    name: '',
                    value: '',
                    remark: '',
                    sort: ((!sort&&sort!=0)?this.dataTree.length:sort)+1,
                    isDisabled: 0,
                }
            },
            editData(id, code, name, data) {
                this.type = 1;
                this.editDialogVisible = true;
                this.editTitle = '编辑数据字典信息'
                this.$api.getDataDictionaryById({id: id}).then(res => {
                    if (res.code == 200) {
                        this.parentName = res.data.parentName == null ? "" : res.data.parentName;
                        this.dataForm = {
                            id: res.data.id,
                            parentCode: code == null ? "" : code,
                            code: res.data.code,
                            wholeCode: res.data.wholeCode,
                            name: res.data.name,
                            value: res.data.value,
                            remark: res.data.remark,
                            sort: res.data.sort,
                            isDisabled: res.data.isDisabled == 0 ? false : true,
                        }
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },

            deleteData(id) {
                this.$confirm('你确定要删除该数据字典吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.deleteDataDictionary({id: id}).then(res => {
                        if (res.code == 200) {
                            this.displayDictionary()
                            this.$message.success(res.message)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }).catch(() => {
                })
            },

            cancel(formName) {
                this.editDialogVisible = false;
                this.$refs[formName].resetFields();
                this.parentName = '';
                this.dataForm = {
                    id: null,
                    parentCode: "",
                    code: '',
                    wholeCode: '',
                    name: '',
                    value: '',
                    remark: '',
                    sort: null,
                    isDisabled: 0,
                }
            },

            submit(formName, type) {
                this.isLoading = true;
                if (this.dataForm.isDisabled == false) {
                    this.dataForm.isDisabled = 0;
                } else {
                    this.dataForm.isDisabled = 1;
                }
                this.$refs[formName].validate((valid) => {
                    if (type == 0) {
                        this.$api.saveDataDictionary(this.dataForm).then(res => {
                            if (res.code == 200) {
                                this.displayDictionary()
                                this.editDialogVisible = false;
                                this.$message.success(res.message)
                                this.isLoading = false;
                            } else {
                                this.$message.error(res.message)
                                this.isLoading = false;
                            }
                        })
                    } else {
                        this.$api.updateDataDictionary(this.dataForm).then(res => {
                            if (res.code == 200) {
                                this.displayDictionary()
                                this.editDialogVisible = false;
                                this.$message.success(res.message)
                                this.isLoading = false;
                            } else {
                                this.$message.error(res.message)
                                this.isLoading = false;
                            }
                        })
                    }
                });
            },
            displayOper(id) {
                this.treeId = id
                this.isDisplay = 'visible';
                this.$api.getDataDictionaryById({id: id}).then(res => {
                    if (res.code == 200) {
                        this.parentData = {
                            id: res.data.id,
                            parentCode: res.data.parentCode == '' ? "无" : res.data.parentCode,
                            code: res.data.code,
                            wholeCode: res.data.wholeCode,
                            name: res.data.name,
                            value: res.data.value,
                            remark: res.data.remark,
                            sort: res.data.sort,
                            isDisabled: res.data.isDisabled,
                        }
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            hiddenOper() {
                this.isDisplay = 'hidden';
            },
            handleNodeClick(data) {
                this.dataForm.name = data.label
            },
            displayDictionary() {
                this.$api.getDataDictionary().then(res => {
                    if (res.code == 200) {
                        this.dataTree = res.data;
                    } else {
                        this.$message.error(res.message)
                    }
                })
            }
        },
        created() {
            this.displayDictionary();
        }
    };
</script>

<style scoped>
    .page-body, .data-dispaly {
        height: 100%;
        width: 100%;
        background: #FFFFFF;
    }

    .iconfont {
        font-family: "iconfont" !important;
        font-size: 15px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        margin-left: 10px;
        display: block;
        cursor: pointer;
    }


    .text-oper {
        display: flex;
    }

    .data-body {
        margin-left: 5px;
        height: 81vh;
        overflow-y: auto;
    }

    .custom-tree-node {
        display: flex;
    }

    .parent {
        font-size: 20px;
    }

    .add-parent {
        margin: 10px;
    }
</style>